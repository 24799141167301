import {CCol, CRow, CWidgetStatsE} from "@coreui/react";
import {CChartBar} from "@coreui/react-chartjs";
import React from "react";

const StatisticsView = () => {
    return(
        <CRow>
            <CCol xs={6}>
                <CWidgetStatsE
                    className="mb-3"
                    chart={
                        <CChartBar
                            className="mx-auto"
                            style={{ height: '40px', width: '80px' }}
                            data={{
                                labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'S', 'M'],
                                datasets: [
                                    {
                                        backgroundColor: '#321fdb',
                                        borderColor: 'transparent',
                                        borderWidth: 1,
                                        data: [41, 78, 51, 66, 74, 42, 89, 97, 87, 84, 78, 88, 67, 45, 47],
                                    },
                                ],
                            }}
                            options={{
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                scales: {
                                    x: {
                                        display: false,
                                    },
                                    y: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    }
                    title="Doküman Arama Kullanımı"
                    value="TODO"
                />
            </CCol>
            <CCol xs={6}>
                <CWidgetStatsE
                    className="mb-3"
                    chart={
                        <CChartBar
                            className="mx-auto"
                            style={{ height: '40px', width: '80px' }}
                            data={{
                                labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'S', 'M'],
                                datasets: [
                                    {
                                        backgroundColor: '#321fdb',
                                        borderColor: 'transparent',
                                        borderWidth: 1,
                                        data: [41, 78, 51, 66, 74, 42, 89, 97, 87, 84, 78, 88, 67, 45, 47],
                                    },
                                ],
                            }}
                            options={{
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                },
                                scales: {
                                    x: {
                                        display: false,
                                    },
                                    y: {
                                        display: false,
                                    },
                                },
                            }}
                        />
                    }
                    title="Yapay Zeka ile Arama Kullanımı"
                    value="TODO"
                />
            </CCol>
        </CRow>
    );
}

export default StatisticsView;