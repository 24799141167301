const dev = {
    apiEndpoint: 'http://localhost:8000',
};

const prod = {
    apiEndpoint: 'https://leagle-backend.azurewebsites.net',
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;