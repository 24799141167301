import React from 'react';
import { Typewriter } from 'react-simple-typewriter';

const GreetingMessage = ({ user }) => {
    let message;

    if (user && user.user) {
        const displayName = user.user.displayName;
        const firstName = displayName ? displayName.split(' ')[0] : null;

        if (firstName) {
            message = `Merhaba ${firstName || user.user.email || 'kullanıcı'}, güncel resmi kararlara buradan ulaşabilirsin.`;
        } else {
            message = `Merhaba ${displayName || user.user.email || 'kullanıcı'}, güncel resmi kararlara buradan ulaşabilirsin.`;
        }
    } else {
        message = 'Merhaba kullanıcı, güncel resmi kararlara buradan ulaşabilirsin.';
    }

    return (
        <div>
            <Typewriter
                words={[message]}
                loop={1}
                cursor
                cursorStyle='_'
                typeSpeed={40}
                deleteSpeed={50}
                delaySpeed={1000}
            />
        </div>
    );
};

export default GreetingMessage;
