import React, {useContext, useState} from 'react';
import axios from 'axios';
import {
    CContainer,
    CRow,
    CCol,
    CButton,
    CCard,
    CCardHeader,
} from '@coreui/react';
import SelectedDocumentView from './SelectedDocumentView';
import config from "../config";
import {AuthContext} from "../contexts/AuthContext";
import GreetingMessage from "../components/Greeting";



const MevzuatView = () => {
    const user = useContext(AuthContext);
    const [staticResults, setStaticResults] = useState([]);
    const [results, setResults] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [activeButton, setActiveButton] = useState(null);

    const fetchDocuments = async (documentType) => {
        console.log(user)
        setIsLoading(true);
        try {
            const endpoint = `${config.apiEndpoint}/dokuman_arama_motoru/top-10-${documentType}/`;
            console.log(endpoint)
            const response = await axios.get(endpoint);
            setResults(response.data.data);
            setStaticResults(response.data.data);
        } catch (error) {
            console.error(`Error fetching ${documentType} documents:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleButtonClick = (documentType) => {
        setActiveButton(documentType);
        fetchDocuments(documentType);
    };

    const sendUserToGOV = (link) => {
        window.open(link, '_blank');
    };

    const groupResultsByDate = (results) => {
        return results.reduce((groups, result) => {
            const date = result.resmiGazeteTarihi.trim();
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(result);
            return groups;
        }, {});
    };

    const renderGroupedResults = (groupedResults) => (
        <CContainer className="pb-5">
            {Object.keys(groupedResults).map((date) => (
                <div key={date} style={{marginBottom:'15px'}}>
                    <h3>Resmi Gazete Tarihi: {date}</h3>
                    {groupedResults[date].map((result, index) => (
                        <CCard onClick={() => sendUserToGOV(result.link)} className="mb-3" key={index}>
                            <CCardHeader as="h3" style={{ padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <div style={{ maxWidth: '80%' }}>
                                        <h3 style={{ marginRight: '20px', marginBottom: '5px', fontWeight: '600', fontSize: '14px' }}>{result.mevAdi.trim()}</h3>
                                        <h4 style={{ margin: '0', color: '#555', fontSize: '13px' }}>Mevzuat Numarası: {result.mevzuatNo.trim()}, Tertip: {result.mevzuatTertip.trim()}, Resmi Gazete Tarihi: {result.resmiGazeteTarihi.trim()}, Sayısı: {result.resmiGazeteSayisi.trim()}</h4>
                                    </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <div style={{ fontSize: '13px', marginBottom: '5px', fontWeight: '600'}}>{result.mevzuat_type_name.trim()}</div>
                                        <div style={{ fontSize: '13px', color: '#888' }}>Kabul Tarihi: {result.kabulTarih ? result.kabulTarih.trim() : result.date.trim()}</div>
                                    </div>
                                </div>
                            </CCardHeader>
                        </CCard>
                    ))}
                </div>
            ))}
        </CContainer>
    );

    const renderSearchResults = () => {
        const groupedResults = groupResultsByDate(results);
        return renderGroupedResults(groupedResults);
    };

    return (
        <div>

            <CContainer className="py-5">
                <CRow className="justify-content-center">
                    <CCol md="lg" style={{justifyContent: 'center', display: 'flex', maxWidth: '80%' ,marginBottom:'30px' }}>
                        <GreetingMessage user={user} />
                    </CCol>
                </CRow>
                <CRow className="justify-content-center">

                    <CCol md="lg" style={{justifyContent: 'center', display: 'flex', maxWidth: '80%' }}>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'mevzuat' ? '#6B1A28' : '', color: activeButton === 'mevzuat' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('mevzuat')}
                        >
                            Tümü
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'kanunlar' ? '#6B1A28' : '', color: activeButton === 'kanunlar' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('kanunlar')}
                        >
                            Kanunlar
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'cumhurbaskanligi_kararnameleri' ? '#6B1A28' : '', color: activeButton === 'cumhurbaskanligi_kararnameleri' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('cumhurbaskanligi_kararnameleri')}
                        >
                            C. Kararnameleri
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'yonetmelikler' ? '#6B1A28' : '', color: activeButton === 'yonetmelikler' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('yonetmelikler')}
                        >
                            C. ve Bakanlar Kurulu Yönetmelikleri
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'cumhurbaskani_kararlari' ? '#6B1A28' : '', color: activeButton === 'cumhurbaskani_kararlari' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('cumhurbaskani_kararlari')}
                        >
                            C. Kararları
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'cumhurbaskanligi_genelgeleri' ? '#6B1A28' : '', color: activeButton === 'cumhurbaskanligi_genelgeleri' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('cumhurbaskanligi_genelgeleri')}
                        >
                            C. Genelgeleri
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'kanun_hukmunde_kararnameler' ? '#6B1A28' : '', color: activeButton === 'kanun_hukmunde_kararnameler' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('kanun_hukmunde_kararnameler')}
                        >
                            KHK
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'tuzukler' ? '#6B1A28' : '', color: activeButton === 'tuzukler' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('tuzukler')}
                        >
                            Tüzükler
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'kurum_yonetmelikleri' ? '#6B1A28' : '', color: activeButton === 'kurum_yonetmelikleri' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('kurum_yonetmelikleri')}
                        >
                            Kurum, Kuruluş ve Üniversite Yön.
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'tebligler' ? '#6B1A28' : '', color: activeButton === 'tebligler' ? '#EFE8E1' : '' }}
                            onClick={() => handleButtonClick('tebligler')}
                        >
                            Tebliğler
                        </CButton>
                    </CCol>
                </CRow>
            </CContainer>
            {
                selectedDocument
                    ? <SelectedDocumentView selectedDocument={selectedDocument} />
                    : renderSearchResults()
            }
        </div>
    );
};

export default MevzuatView;

