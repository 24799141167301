import React from 'react';
import { useContext } from 'react';
import logo from '../assets/images/leagle_logo2.png';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import {CButton, CContainer} from '@coreui/react';

const Header = () => {
  const { user, logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <CContainer >
      <div className="navbar" style={{ padding: '15px 20px'}}>
      <div className="navbar-logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
    
      {/* Repeat this block for other menu items */}
      <div className="menu-items">
        <Link to="https://www.leagleapp.com" className="menu-button">Ana Sayfa</Link>
      </div>
      <div className="menu-items">
        <Link to="https://www.leagleapp.com/hakkımızda" className="menu-button">Hakkımızda</Link>
      </div>
      <div className="menu-items">
        <Link to="/profile" className="menu-button">Profilim</Link>
      </div>
      {/** 
        <div className="menu-items">
          <Link to="/anlasmali-bosanma" className="menu-button">Anlaşmalı Boşanma</Link>
        </div>
      **/}
      <div className="menu-items">
      <Link to="/dokuman-arama-page" className="menu-button">Leagle Doküman Arama</Link>
      </div>
      <div className="menu-items">
      <Link to="/yapay-zeka-ile-arama" className="menu-button">Yapay Zeka ile Arama</Link>
      </div>
      <div className="menu-items">
      <Link to="/mevzuat" className="menu-button">Günce</Link>
      </div>
      <div className="auth-container">
        {user ? (
          <>
            <span className="greeting" style={{ marginRight: '10px' }}>
              Merhaba, {user.displayName || user.email || 'Kullanıcı'}
            </span>
            <CButton color="primary" onClick={handleLogout}>Çıkış Yap</CButton>
          </>
        ) : (
          <Link to="/login">
            <CButton color="primary">Giriş Yap</CButton>
          </Link>
        )}
      </div>
    </div>
    </CContainer>
    
  );
};

export default Header;
