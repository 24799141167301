import React, { useRef, useState } from 'react';
import { CContainer, CRow, CCol, CButton, CCard, CCardHeader, CCardBody, CForm, CFormInput, CSpinner } from '@coreui/react';
import { formatDate, getScoreColor, getScoreWidth, parseIctihatBody, chatWithDocument, summarizeDocument } from '../utils/documentSearchUtils';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { saveDocumentTalkToFirestore } from '../utils/documentSearchUtils';
import { Button } from "primereact/button";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { Toast } from "primereact/toast";

const SelectedDocumentView = () => {
    const toast = useRef(null);
    const sessionID = uuidv4();
    const location = useLocation();
    const selectedDocument = location.state?.document;
    const query = location.state?.query;
    const activeButton = location.state?.activeButton;
    const results = location.state?.results;
    const navigate = useNavigate();
    const [userInput, setUserInput] = useState('');
    const [conversation, setConversation] = useState([]);
    const [summary, setSummary] = useState('');
    const [isChatMinimized, setIsChatMinimized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const showAddedToast = () => {
        if (toast.current) {
            toast.current.show({ severity: 'success', summary: 'Başarılı!', detail: 'Doküman favorilere eklendi.', life: 3000 });
        } else {
            console.error("Toast ref is null");
        }
    };
    const showAlreadyExistsToast = () => {
        if (toast.current) {
            toast.current.show({ severity: 'info', summary: 'Başarılı!', detail: 'Doküman zaten favorilerinde.', life: 3000 });
        } else {
            console.error("Toast ref is null");
        }
    };

    const showErrorToast = () => {
        if (toast.current) {
            toast.current.show({ severity: 'danger', summary: 'Hata!', detail: 'Doküman favorilere eklenirken bir hata oluştu.', life: 3000 });
        } else {
            console.error("Toast ref is null");
        }
    };

    const goBack = () => {
        console.log(activeButton)
        navigate('/dokuman-arama-page', { state: { query, results, activeButton } }); // Pass activeButton back
    };

    const handleChat = async () => {
        setIsLoading(true);
        const response = await chatWithDocument(selectedDocument, userInput);
        setConversation([...conversation, { user: userInput, response }]);
        saveDocumentTalkToFirestore(userInput, response, sessionID);
        setUserInput('');
        setIsLoading(false);
    };

    const handleSummarize = async () => {
        setIsLoading(true);
        const summary = await summarizeDocument(selectedDocument);
        saveDocumentTalkToFirestore('özetle', summary, sessionID);
        setSummary(summary);
        setIsLoading(false);
    };

    const toggleChatSize = () => {
        setIsChatMinimized(!isChatMinimized);
    };

    if (!selectedDocument) {
        return <div>Loading...</div>;
    }

    const addFavoritesToFirestore = async () => {
        const user = auth.currentUser;
        if (!user) {
            console.error("No authenticated user found.");
            return;
        }

        const userId = user.uid;
        const favoritesRef = doc(db, `users/${userId}/favorites`, selectedDocument.id);

        const docSnap = await getDoc(favoritesRef);

        if (docSnap.exists()) {
            try {
                showAlreadyExistsToast();
            } catch (error) {
            }
        } else {
            try {
                await setDoc(favoritesRef, selectedDocument);
                showAddedToast();
            } catch (error) {
                showErrorToast();
            }
        }
    };

    return (
        <CContainer style={{ marginTop: '5vh' }}>
            <Toast ref={toast} />
            <CRow className="justify-content-center">
                <CCol md="lg">
                    {selectedDocument.link ? (
                        <CCard>
                            <CCardHeader as="h3" style={{padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
                                <Button onClick={addFavoritesToFirestore} style={{ marginBottom: '10px', color: '#6B1A28' }} icon="pi pi-heart" rounded text severity="help" aria-label="Favorite" />
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '10px'
                                }}>
                                    <div>
                                        <h3 style={{margin: '0', fontSize:'14px', fontWeight: '600'}}>{selectedDocument.mevAdi}</h3>
                                        <h4 style={{margin: '0', color: '#555'}}>{selectedDocument.mevzuat_type_name}</h4>
                                        <h4 style={{margin: '0', color: '#555'}}>Mevzuat No: {selectedDocument.mevzuatNo}</h4>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        <div style={{
                                            fontWeight: '600',
                                            color: getScoreColor(selectedDocument["@search.rerankerScore"])
                                        }}>Skor: {(25 * selectedDocument["@search.rerankerScore"]).toFixed(0)}</div>

                                        <div style={{
                                            fontSize: '0.85em',
                                            color: '#888'
                                        }}>{selectedDocument.resmiGazeteTarihi}</div>
                                    </div>
                                </div>
                                <div style={{
                                    height: '10px',
                                    width: '100%',
                                    backgroundColor: '#f0f0f0',
                                    borderRadius: '5px'
                                }}>
                                    <div
                                        style={{
                                            height: '10px',
                                            width: getScoreWidth(selectedDocument["@search.rerankerScore"]),
                                            backgroundColor: getScoreColor(selectedDocument["@search.rerankerScore"]),
                                            borderRadius: '5px',
                                        }}>
                                    </div>
                                </div>
                            </CCardHeader>
                            <CButton color="danger" variant="outline" style={{
                                position: 'fixed',
                                top: '50vh',
                                left: '20px',
                                zIndex: 1000,
                                padding: '5px 10px',
                                fontSize: '12px',
                            }} onClick={goBack}>Geri</CButton>
                            <CCardBody>
                                <div className="ictihat-content">
                                    {parseIctihatBody(selectedDocument.mevzuat_body)}
                                </div>
                            </CCardBody>
                        </CCard>
                    ) : (
                        <CCard>

                            <CCardHeader as="h3" style={{ padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <div>
                                        <Button onClick={addFavoritesToFirestore} style={{ marginBottom: '10px', color: '#6B1A28' }} icon="pi pi-heart" rounded text severity="help" aria-label="Favorite" />
                                        <h3 style={{ margin: '0', fontWeight: '600' }}>Karar No: {selectedDocument.karar_no}</h3>
                                        <h4 style={{ margin: '0', color: '#555' }}>Esas No: {selectedDocument.esas_no}</h4>
                                    </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <div style={{ fontWeight: '600', color: getScoreColor(selectedDocument["@search.rerankerScore"]) }}>Skor: {(25 * selectedDocument["@search.rerankerScore"]).toFixed(0)}</div>
                                        <div style={{ fontSize: '0.85em', color: '#888' }}>{selectedDocument.daire}, {formatDate(selectedDocument.date)}</div>
                                    </div>
                                </div>
                                <div style={{ height: '10px', width: '100%', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
                                    <div
                                        style={{
                                            height: '10px',
                                            width: getScoreWidth(selectedDocument["@search.rerankerScore"]),
                                            backgroundColor: getScoreColor(selectedDocument["@search.rerankerScore"]),
                                            borderRadius: '5px',
                                        }}>
                                    </div>
                                </div>
                            </CCardHeader>
                            <CButton color="danger" variant="outline" style={{
                                position: 'fixed',
                                top: '50vh',
                                left: '20px',
                                zIndex: 1000,
                                padding: '5px 10px',
                                fontSize: '12px',
                            }} onClick={goBack}>Geri</CButton>
                            <CCardBody>
                                <div className="ictihat-content">
                                    {parseIctihatBody(selectedDocument.ictihat_body)}
                                </div>
                            </CCardBody>
                        </CCard>
                    )}

                </CCol>
            </CRow>
            <CContainer className={`document-chat-container ${isChatMinimized ? 'minimized' : ''}`} style={{ position: 'fixed', height: isChatMinimized ? '50px' : '400px', width: '500px', transition: 'height 0.3s', overflow: 'hidden' }}>
                <CCard className="document-chat-area" style={{ height: '100%' }}>
                    <CCardHeader>Leagle AI</CCardHeader>
                    <CCardBody className={`document-chat-content ${isChatMinimized ? 'hidden-content' : ''}`} style={{ maxHeight: '100%', overflowY: 'auto' }}>
                        <div className="summary-section">
                            {summary && (
                                <div>
                                    <h4 style={{ fontWeight: '700' }}>Özet</h4>
                                    <div style={{ whiteSpace: 'pre-line', fontSize: '0.85em' }}>{summary}</div>
                                    <hr />
                                </div>
                            )}
                        </div>
                        <div>
                            {conversation.map((chat, index) => (
                                <div key={index} style={{ fontSize: '0.85em' }}>
                                    <strong>Kullanıcı:</strong> {chat.user}
                                    <br />
                                    <strong>Yanıt:</strong> {chat.response}
                                    <hr />
                                </div>
                            ))}
                        </div>
                        {isLoading && (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                <CSpinner />
                            </div>
                        )}
                    </CCardBody>
                    {!isChatMinimized && (
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <CForm className="d-flex" style={{ padding: '10px', width: '100%', maxWidth: '450px', justifyContent: 'space-between' }}>
                                    <CFormInput
                                        type="text"
                                        id="userInput"
                                        value={userInput}
                                        onChange={(e) => setUserInput(e.target.value)}
                                        placeholder="Dokümana soru sorun..."
                                        className="me-2"
                                        style={{ flexGrow: 1, marginRight: '10px' }}
                                    />
                                    <CButton onClick={handleChat} color="secondary" style={{ padding: '10px', flexGrow: 1, marginRight: '10px' }} disabled={isLoading}>
                                        Gönder
                                    </CButton>
                                    <CButton onClick={handleSummarize} color="primary" style={{ padding: '10px', flexGrow: 1 }} disabled={isLoading}>
                                        Özetle
                                    </CButton>
                                </CForm>
                            </div>
                        </>
                    )}
                    <CButton onClick={toggleChatSize} color="primary" style={{ position: 'absolute', top: '2.5px', right: '2.5px', padding: '2px', fontSize: '12px', width: '25px', height: '25px' }}>
                        {isChatMinimized ? '+' : '-'}
                    </CButton>
                </CCard>
            </CContainer>
        </CContainer>
    );
};

export default SelectedDocumentView;
