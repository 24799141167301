import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import React, { useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';

function HukukDairesiSelector({ selectedDairesi, setSelectedDairesi }) {
    const hukukDairesiOptions = Array.from({ length: 18 }, (_, i) => ({
        name: `${i + 1}. Hukuk Dairesi`,
        code: i + 1
    }));

    return (
        <div className="card" style={{ margin: '5px' }}>
            <MultiSelect style={{fontFamily:'Futura-Book'}}
                value={selectedDairesi}
                onChange={(e) => setSelectedDairesi(e.value)}
                options={hukukDairesiOptions}
                optionLabel="name"
                placeholder="Hukuk Dairesine göre filtrele"
                display="chip"
                className="w-full"
            />
        </div>
    );
}

export { HukukDairesiSelector };

function CezaDairesiSelector({ selectedDairesi, setSelectedDairesi }) {
    const cezaDairesiOptions = Array.from({ length: 18 }, (_, i) => ({
        name: `${i + 1}. Ceza Dairesi`,
        code: i + 1
    }));

    return (
        <div className="card" style={{ margin: '5px' }}>
            <MultiSelect style={{fontFamily:'Futura-Book'}}
                value={selectedDairesi}
                onChange={(e) => setSelectedDairesi(e.value)}
                options={cezaDairesiOptions}
                optionLabel="name"
                placeholder="Ceza dairesine göre filtrele"
                display="chip"
                className="w-full"
            />
        </div>
    );
}

export { CezaDairesiSelector };

const DateFilter = ({ startDateDay, setStartDateDay, startDateMonth, setStartDateMonth, startDateYear, setStartDateYear, endDateDay, setEndDateDay, endDateMonth, setEndDateMonth, endDateYear, setEndDateYear }) => {
  return (
    <div>
      <div className="input-group mb-3">
        <span className="input-group-text">Başlangıç Tarihi</span>
        <input type="text" placeholder="Gün" className="form-control" value={startDateDay} onChange={(e) => setStartDateDay(e.target.value)} aria-label="Gün"/>
        <input type="text" placeholder="Ay" className="form-control" value={startDateMonth} onChange={(e) => setStartDateMonth(e.target.value)} aria-label="Ay"/>
        <input type="text" placeholder="Yıl" className="form-control" value={startDateYear} onChange={(e) => setStartDateYear(e.target.value)} aria-label="Yıl"/>
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text">Bitiş Tarihi</span>
        <input type="text" placeholder="Gün" className="form-control" value={endDateDay} onChange={(e) => setEndDateDay(e.target.value)} aria-label="Gün"/>
        <input type="text" placeholder="Ay" className="form-control" value={endDateMonth} onChange={(e) => setEndDateMonth(e.target.value)} aria-label="Ay"/>
        <input type="text" placeholder="Yıl" className="form-control" value={endDateYear} onChange={(e) => setEndDateYear(e.target.value)} aria-label="Yıl"/>
      </div>
    </div>
  );
};

export {DateFilter};

function MevzuatCategorySelector({ selectedCategory, setSelectedCategory }) {
    const mevzuatOptions = [
        { name: 'Kanunlar', code: 'kanun' },
        { name: 'C. Kararnameleri', code: 'cumhur_kararname' },
        { name: 'C. ve Bakanlar Kurulu Yönetmelikleri', code: 'cumhur_yonetmelik' },
        { name: 'C. Kararları', code: 'cumhur_karar' },
        { name: 'C. Genelgeleri', code: 'cumhur_genelge' },
        { name: 'KHK', code: 'khk' },
        { name: 'Tüzükler', code: 'tuzuk' },
        { name: 'Kurum, Kuruluş ve Üniversite Yönetmelikleri', code: 'kurum_yonetmelik' },
        { name: 'Tebliğler', code: 'teblig' }
    ];


    return (
        <div className="card" style={{ margin: '5px' }}>
            <MultiSelect style={{fontFamily:'Futura-Book'}}
                         value={selectedCategory}
                         onChange={(e) => setSelectedCategory(e.value)}
                         options={mevzuatOptions}
                         optionLabel="name"
                         placeholder="Kategoriye göre filtrele"
                         display="chip"
                         className="w-full"
            />
        </div>
    );
}

export {MevzuatCategorySelector}


