import React, {useState, useEffect,} from 'react';
import axios from 'axios';
import {CContainer, CRow, CCol, CButton, CCard, CCardHeader, CCardBody, CLink, CFooter} from '@coreui/react';
import SelectedDocumentView from './SelectedDocumentView';
import {formatDate, getScoreColor, getScoreWidth, saveSearchToFirestore} from '../utils/documentSearchUtils';
import {useNavigate, useLocation} from 'react-router-dom';
import config from "../config";
import DokumanAramaSearchInput from "../components/DokumanAramaSearchInput";
import IctihatFilterPanel from "../components/IctihatFilterPanel";
import MevzuatFilterPanel from "../components/MevzuatFilterPanel";


const DokumanAramaView = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [query, setQuery] = useState('');
    const [staticResults, setStaticResults] = useState([]);
    const [results, setResults] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [startDateDay, setStartDateDay] = useState('');
    const [startDateMonth, setStartDateMonth] = useState('');
    const [startDateYear, setStartDateYear] = useState('');
    const [endDateDay, setEndDateDay] = useState('');
    const [endDateMonth, setEndDateMonth] = useState('');
    const [endDateYear, setEndDateYear] = useState('');
    const [selectedHukukDairesi, setSelectedHukukDairesi] = useState([]);
    const [selectedCezaDairesi, setSelectedCezaDairesi] = useState([]);
    const [selectedMevzuatCategory, setSelectedMevzuatCategory] = useState([]);
    const [activeButton, setActiveButton] = useState('yargitay');

    const displayDetailedCard = (document) => {
        navigate('/selected-document', { state: { document: document, query: query, results: staticResults, activeButton: activeButton } });
    };

    useEffect(() => {
        if (location.state) {
            if (location.state.query) {
                setQuery(location.state.query);
            }
            if (location.state.results) {
                setResults(location.state.results);
                setStaticResults(location.state.results);
            }
            if(location.state.activeButton){
                setActiveButton(location.state.activeButton)
            }
        }
    }, [location]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        setIsLoading(true);
        try {
            const url = `${config.apiEndpoint}/dokuman_arama_motoru/ai_search/?query=${query}&index=${activeButton}`;
            saveSearchToFirestore(query);
            const response = await axios.get(url);
            setResults(response.data.value)
            setStaticResults(response.data.value)
            console.log(response)
        } catch (error) {
            console.error('Error fetching filtered search results:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const sortByRelevancy = () => {
        setResults(staticResults)
    }
    const sortByDate = () => {
        let sortedArray = [...results];

        if (activeButton === 'mevzuat') {
            // Parse and sort dates in DD.MM.YYYY format
            sortedArray.sort((a, b) => {
                const dateA = new Date(a.resmiGazeteTarihi.split('.').reverse().join('-'));
                const dateB = new Date(b.resmiGazeteTarihi.split('.').reverse().join('-'));
                return dateB - dateA;
            });
        } else {
            // Parse and sort ISO dates
            sortedArray.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
        console.log('Sorted results');
        setResults(sortedArray);
    };


    const filterMevzuatResults = () => {
        let filteredArray = [...staticResults];
        filteredArray = filterByMevzuatCategory(filteredArray);
        filteredArray = filterByDate(filteredArray);
        setResults(filteredArray);
    }

    const filterByMevzuatCategory = (items) => {
        if (selectedMevzuatCategory.length === 0) return items;
        return items.filter(item => selectedMevzuatCategory.some(category => item.mevzuat_type === category.code));
    }

    const filterIctihatResults = () => {
        let filteredArray = [...staticResults];
        filteredArray = filterByDaire(filteredArray);
        filteredArray = filterByDate(filteredArray);
        setResults(filteredArray);
    };

    const filterByDate = (items) => {
        if (startDateDay && startDateMonth && startDateYear && endDateDay && endDateMonth && endDateYear) {
            const beginDate = new Date(startDateYear, startDateMonth - 1, startDateDay);
            const endDate = new Date(endDateYear, endDateMonth - 1, endDateDay);
            return items.filter(item => {
                const itemDate = new Date(item.date);
                return itemDate >= beginDate && itemDate <= endDate;
            });
        }
        return items;
    };

    const filterByDaire = items => {
        const combinedDairesi = [...selectedHukukDairesi, ...selectedCezaDairesi];
        if (combinedDairesi.length === 0) return items;
        return items.filter(item => combinedDairesi.some(daire => item.daire === daire.name));
    };



    const renderSearchResults = () => (
        <CContainer className="pb-5">
            <CRow>
                <CCol xs={8}>
                    {activeButton === 'mevzuat' ? (
                        <CContainer style={{overflowY:'auto'}}>
                            {results.map((result, index) => (
                                <CCard onClick={() => displayDetailedCard(result)} className="mb-3" key={index}>
                                    <CCardHeader as="h3" style={{padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '10px'
                                        }}>
                                            <div>
                                                <h3 style={{margin: '0', fontSize:'14px', fontWeight: '600'}}>{result.mevAdi}</h3>
                                                <h4 style={{margin: '0', color: '#555'}}>{result.mevzuat_type_name}</h4>
                                                <h4 style={{margin: '0', color: '#555'}}>Mevzuat No: {result.mevzuatNo}</h4>
                                            </div>
                                            <div style={{textAlign: 'right'}}>
                                                <div style={{
                                                    fontWeight: '600',
                                                    color: getScoreColor(result["@search.rerankerScore"])
                                                }}>Skor: {(25 * result["@search.rerankerScore"]).toFixed(0)}</div>

                                                <div style={{
                                                    fontSize: '0.85em',
                                                    color: '#888'
                                                }}>{result.resmiGazeteTarihi}</div>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: '10px',
                                            width: '100%',
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: '5px'
                                        }}>
                                            <div
                                                style={{
                                                    height: '10px',
                                                    width: getScoreWidth(result["@search.rerankerScore"]),
                                                    backgroundColor: getScoreColor(result["@search.rerankerScore"]),
                                                    borderRadius: '5px',
                                                }}>
                                            </div>
                                        </div>
                                    </CCardHeader>
                                    <CCardBody>
                                    </CCardBody>
                                </CCard>
                            ))}
                        </CContainer>
                    ) : (
                        <CContainer style={{overflowY:'auto'}}>
                            {results.map((result, index) => (
                                <CCard onClick={() => displayDetailedCard(result)} className="mb-3" key={index}>
                                    <CCardHeader as="h3" style={{padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '10px'
                                        }}>
                                            <div>
                                                <h3 style={{margin: '0', fontWeight: '600'}}>Karar No: {result.karar_no}</h3>
                                                <h4 style={{margin: '0', color: '#555'}}>Esas No: {result.esas_no}</h4>
                                            </div>
                                            <div style={{textAlign: 'right'}}>
                                                <div style={{
                                                    fontWeight: '600',
                                                    color: getScoreColor(result["@search.rerankerScore"])
                                                }}>Skor: {(25 * result["@search.rerankerScore"]).toFixed(0)}</div>

                                                <div style={{
                                                    fontSize: '0.85em',
                                                    color: '#888'
                                                }}>{result.karar_yeri}, {result.daire}, {formatDate(result.date)}</div>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: '10px',
                                            width: '100%',
                                            backgroundColor: '#f0f0f0',
                                            borderRadius: '5px'
                                        }}>
                                            <div
                                                style={{
                                                    height: '10px',
                                                    width: getScoreWidth(result["@search.rerankerScore"]),
                                                    backgroundColor: getScoreColor(result["@search.rerankerScore"]),
                                                    borderRadius: '5px',
                                                }}>
                                            </div>
                                        </div>
                                    </CCardHeader>
                                    <CCardBody>
                                    </CCardBody>
                                </CCard>
                            ))}
                        </CContainer>
                    )}

                </CCol>
                <CCol xs={4}>
                    {activeButton === 'mevzuat' ? (
                        <MevzuatFilterPanel
                            startDateDay={startDateDay}
                            setStartDateDay={setStartDateDay}
                            startDateMonth={startDateMonth}
                            setStartDateMonth={setStartDateMonth}
                            startDateYear={startDateYear}
                            setStartDateYear={setStartDateYear}
                            endDateDay={endDateDay}
                            setEndDateDay={setEndDateDay}
                            endDateMonth={endDateMonth}
                            setEndDateMonth={setEndDateMonth}
                            endDateYear={endDateYear}
                            setEndDateYear={setEndDateYear}
                            selectedMevzuatCategory={selectedMevzuatCategory}
                            setSelectedMevzuatCategory={setSelectedMevzuatCategory}
                            filterResults={filterMevzuatResults}
                            sortByDate={sortByDate}
                            sortByRelevancy={sortByRelevancy}
                        />
                    ) : (
                        <IctihatFilterPanel
                            startDateDay={startDateDay}
                            setStartDateDay={setStartDateDay}
                            startDateMonth={startDateMonth}
                            setStartDateMonth={setStartDateMonth}
                            startDateYear={startDateYear}
                            setStartDateYear={setStartDateYear}
                            endDateDay={endDateDay}
                            setEndDateDay={setEndDateDay}
                            endDateMonth={endDateMonth}
                            setEndDateMonth={setEndDateMonth}
                            endDateYear={endDateYear}
                            setEndDateYear={setEndDateYear}
                            selectedHukukDairesi={selectedHukukDairesi}
                            setSelectedHukukDairesi={setSelectedHukukDairesi}
                            selectedCezaDairesi={selectedCezaDairesi}
                            setSelectedCezaDairesi={setSelectedCezaDairesi}
                            filterResults={filterIctihatResults}
                            sortByDate={sortByDate}
                            sortByRelevancy={sortByRelevancy}
                        />
                    )}
                </CCol>
            </CRow>
        </CContainer>
    );

    const changeDocumentTab = (documentType) => {
        setActiveButton(documentType);
        setResults([])
        setStaticResults([])
        setQuery('')
    };

    return (
        <div>
            <CContainer className="py-5">
                <CRow className="justify-content-center">
                    <CCol md="lg" style={{ justifyContent: 'center', display: 'flex', maxWidth: '80%', marginBottom:'10px'}}>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'yargitay' ? '#6B1A28' : '', color: activeButton === 'yargitay' ? '#EFE8E1' : '' }}
                            onClick={() => changeDocumentTab('yargitay')}
                        >
                            Yargıtay
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'danistay' ? '#6B1A28' : '', color: activeButton === 'danistay' ? '#EFE8E1' : '' }}
                            onClick={() => changeDocumentTab('danistay')}
                        >
                            Danıştay
                        </CButton>
                        <CButton
                            style={{ fontSize: '14px', backgroundColor: activeButton === 'mevzuat' ? '#6B1A28' : '', color: activeButton === 'mevzuat' ? '#EFE8E1' : '' }}
                            onClick={() => changeDocumentTab('mevzuat')}
                        >
                            Mevzuat
                        </CButton>
                    </CCol>
                </CRow>
                <CRow className="justify-content-center">
                    <CCol md="lg">
                        <DokumanAramaSearchInput
                            query={query}
                            setQuery={setQuery}
                            handleSearch={handleSearch}
                            handleKeyDown={handleKeyDown}
                            isLoading={isLoading}
                        />
                    </CCol>
                </CRow>
            </CContainer>
            {
                selectedDocument
                    ? <SelectedDocumentView selectedDocument={selectedDocument}/>
                    : renderSearchResults()
            }
        </div>
    );
};

export default DokumanAramaView;

