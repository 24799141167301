import React from 'react';
import { CInputGroup, CFormInput, CButton } from '@coreui/react';

const SearchInput = ({ query, setQuery, handleSearch, handleKeyDown, isLoading }) => {
    return (
        <CInputGroup>
            <CFormInput
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Doküman Ara"
                aria-label="default input example"
                onKeyDown={handleKeyDown}
            />
            <CButton onClick={() => handleSearch()} color="primary" disabled={isLoading}>
                {isLoading ? "Aranıyor..." : "Doküman Ara"}
            </CButton>
        </CInputGroup>
    );
};

export default SearchInput;
