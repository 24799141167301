import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CButton, CCol } from '@coreui/react';
import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebaseConfig';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import logo from '../assets/images/leagle_logo2.png';
import default_profile_image from '../assets/images/default_profile_image.png';

const ProfileNavbar = ({ onTabChange }) => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const [threads, setThreads] = useState([]);

    useEffect(() => {
        const fetchThreads = async () => {
            if (user) {
                const userThreadsRef = collection(db, `users/${user.uid}/threads`);
                const threadSnapshot = await getDocs(userThreadsRef);
                const threadsList = threadSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setThreads(threadsList);
            }
        };

        fetchThreads();
    }, [user]);

    const handleNewTopicClick = (e) => {
        e.preventDefault();
        navigate('/yapay-zeka-ile-arama');
    };

    const handleDeleteThread = async (threadId) => {
        try {
            await deleteDoc(doc(db, `users/${user.uid}/threads`, threadId));
            setThreads(threads.filter(thread => thread.id !== threadId));
        } catch (error) {
            console.error('Error deleting thread:', error);
        }
    };

    return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                height: '100vh',
                width: '250px',
                backgroundColor: '#f8f9fa',
                alignItems: 'center'
            }}>
                <Link to="/dokuman-arama-page" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={logo} alt="Logo" style={{ marginBottom: '20px', width: '60%' }} />
                </Link>

                {/* Navigation Links */}
                <CButton className="nav-link-profile" onClick={() => onTabChange('favorites')}>Favorilerim</CButton>
                <CButton className="nav-link-profile" /* onClick={() =>onTabChange('statistics')}> */>İstatistiklerim (soon)</CButton>

                {/* Profile Link and User Info at the bottom */}
                {user ? (
                    <Link to={"/profile"} onClick={() => onTabChange('profile')} style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 'auto',
                        textDecoration: 'none' // Ensures no underline for the link
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                            cursor: 'pointer'
                        }}>
                            <img src={user.photoURL ? user.photoURL : default_profile_image} alt="Profile" style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }} />
                            <span style={{ color: 'black' }}>{user.displayName || user.email || 'Kullanıcı'}</span>
                        </div>
                    </Link>
                ) : (
                    <Link to="/login" style={{ width: '100%' }}>
                        <CButton color="primary" style={{ width: '100%' }}>Giriş Yap</CButton>
                    </Link>
                )}
            </div>
    );
};

export default ProfileNavbar;
