import axios from 'axios';
import React from 'react';
import {auth, db} from '../firebaseConfig';
import {collection, doc, setDoc, updateDoc, Timestamp, arrayUnion, getDoc} from "firebase/firestore";
import config from "../config";


export const chatWithDocument = async (selectedDocument, userPrompt) => {
    try {

        const response = await axios.post(`${config.apiEndpoint}/chat/chit_chat/`,
            {
                ictihat_body: selectedDocument.ictihat_body ? selectedDocument.ictihat_body : selectedDocument.mevzuat_body,
                prompt: userPrompt,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        return response.data.response;
    } catch (error) {
        console.error('Error during API request:', error);
    }
}


export const summarizeDocument = async (selectedDocument) => {
    try {
        const response = await axios.post(`${config.apiEndpoint}/chat/summarize/`, {
            ictihat_body: selectedDocument.ictihat_body ? selectedDocument.ictihat_body : selectedDocument.mevzuat_body
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data.response;
    } catch (error) {
        console.error('Error during API request:', error);
    }
};

export const getScoreColor = (score) => {
    const normalizedScore = score / 4;
    const red = Math.floor(255 * (1 - normalizedScore));
    const green = Math.floor(255 * normalizedScore);
    return `rgb(${red},${green},0)`;
};

export function formatDate(dateString) {
    const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
    return new Date(dateString).toLocaleDateString('en-GB', options);
}

export const parseIctihatBody = (ictihatBody) => {
    return ictihatBody.trim().split('\n').map((line, index) => {
        if (line.includes('"İçtihat Metni"')) {
            const parts = line.split('"İçtihat Metni"');
            return (
                <React.Fragment key={index}>
                    <p>{"İçtihat Metni"}</p>
                    {parts[1] && <p>{parts[1]}</p>}
                </React.Fragment>
            );
        } else {
            return line.trim() !== "" ? <p key={index}>{line}</p> : <br key={index}/>;
        }
    });
};

export const getScoreWidth = (score) => {
    return `${(score / 4) * 100}%`;
};

export const saveSearchToFirestore = async (query) => {
    const user = auth.currentUser;

    if (!user) {
        console.error("No authenticated user found.");
        return;
    }

    const userId = user.uid;
    const userSearchesRef = collection(db, `users/${userId}/aiSearches`);
    const searchRef = doc(userSearchesRef);

    const search = {
        query,
        createdAt: Timestamp.fromDate(new Date()),
    };

    try {
        await setDoc(searchRef, search);
    } catch (error) {
        console.error("Error saving search query to Firestore:", error);
    }
};

export const saveDocumentTalkToFirestore = async (query, response, sessionID) => {
    const user = auth.currentUser;

    if (!user) {
        console.error("No authenticated user found.");
        return;
    }

    const userId = user.uid;
    const userDocumentViewTalksRef = collection(db, `users/${userId}/documentViewTalks`);

    const sessionRef = doc(userDocumentViewTalksRef, sessionID);
    const docSnap = await getDoc(sessionRef);

    if (docSnap.exists()) {
        // Update the existing thread
        try {
            await updateDoc(sessionRef, {
                responses: arrayUnion(
                    {speaker: 'User', message: query},
                    response
                )
            });
        } catch (error) {
            console.error("Error updating document talk in Firestore:", error);
        }
    } else {
        const thread = {
            responses: [
                {speaker: 'User', message: query},
                response
            ],
            createdAt: Timestamp.fromDate(new Date()),
        };
        try {
            await setDoc(sessionRef, thread);
        } catch (error) {
            console.error("Error creating new document talk session in Firestore:", error);
        }
    }


};