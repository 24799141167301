import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/chat_form.css';

const AnlasmaliBosanmaDilekceForm = () => {

    const navigate = useNavigate();

    const anlasmaliBosanmaData = {
      bosanmaID: '',
      userID : '',
      protokolID: '',
      dilekceID: '',
      userName: '',
      userNationalID: '',
      userAddress: '',
      spouseName: '',
      spouseNationaID: '',
      spouseAddress: '',
      marriageDate: '',
      childrenCount: 0,
      childrenInformationList : [],
      cocuklariKimlerAlabilir : '',
      malRejimiTuru : '',
      nafakaVerilecekMi : '',
      nafakaMiktari : {},
      nafakaOdemeSikligi : '',
      nafakaArtisPlani : '',
    };

    const calculateProgress = () => {
      return ((step / dynamicTotalSteps) * 100).toFixed(0);
    };
    
    const [step, setStep] = useState(0);
    const [userData, setUserData] = useState(anlasmaliBosanmaData);
    const [currentInput, setCurrentInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [currentChild, setCurrentChild] = useState(0);

    const handleInputChange = (event) => {

      // Step 0: Adınız nedir? --> Sadece harfler
      // Step 1: TC kimlik --> 11 hane, sadece sayı
      // Step 2: Adres --> Sayı ve hane
      // Step 3: Eş adı --> Sadece harfler
      // Step 4: Eş TC --> 11 hane, sadece sayı
      // Step 5: Eş adres --> Sayı ve hane
      // Step 6: Evlenme tarihi --> Sadece tarih : (DD.MM.YYYY)
      // Step 7: Çocuk sayısı --> Sadece sayı
      // Step 8, 11, 14.. (Çocuk adı) --> Sadece harfler
      // Step 9, 12, 15.. (Çocuk TC) --> 11 hane, sadece sayı
      // Step 10, 13, 16.. (Çocuk doğum tarihi) --> Sadece tarih : (DD.MM.YYYY)
      
      let input = event.target.value;

      // Handling the children related input steps.
      if(step > 7){
          switch((step - 8) % 3){
            case 0: // Child name
              input = input.replace(/[^a-zA-ZğüşöçĞÜŞÖÇİı\s]/g, '');
              break;
            case 1: // Child TC number
              input = input.replace(/[^0-9]/g, ''); // Allow only numbers
              if (input.length > 11) {
                  input = input.substring(0, 11); // Limit to 11 digits
              }
              break;
            case 2: // Child birth date
              input = input.replace(/[^0-9.]/g, '');
              let parts = input.split('.').map(part => part.slice(0, part.length <= 2 ? 2 : 4));
              if (parts.length > 3) parts = parts.slice(0, 3); // No more than three parts
              input = parts.join('.').slice(0, 10); // Limit overall length
              break;

          }
      }

      // Handling the general input steps.
      switch (step) {
          case 0: // User name - Only letters
          case 3: // Spouse name - Only letters
              input = input.replace(/[^a-zA-ZğüşöçĞÜŞÖÇİı\s]/g, ''); // Allow only letters and whitespace (adjusted for Turkish characters)
              break;

          case 1: // User TC - 11 digits, only numbers
          case 4: // Spouse TC - 11 digits, only numbers
              input = input.replace(/[^0-9]/g, ''); // Allow only numbers
              if (input.length > 11) {
                  input = input.substring(0, 11); // Limit to 11 digits
              }
              break;

          case 2: // Address - Numbers and letters
          case 5: // Spouse Address - Numbers and letters
              input = input.replace(/[^0-9a-zA-ZğüşöçĞÜŞÖÇİı\s.,-:]/g, ''); // Allow numbers, letters, whitespace, comma, period, and dash (adjusted for Turkish characters)
              break;
          case 6: // Marriage Date - Format: DD.MM.YYYY
              input = input.replace(/[^0-9.]/g, '');
              let parts = input.split('.').map(part => part.slice(0, part.length <= 2 ? 2 : 4));
              if (parts.length > 3) parts = parts.slice(0, 3); // No more than three parts
              input = parts.join('.').slice(0, 10); // Limit overall length
              break;
          case 7: // Children count - Only numbers
              input = input.replace(/[^0-9]/g, ''); // Allow only numbers
              break;
          default:
              // Default case if needed
              break;        
      }
      setCurrentInput(input);
    };


    const addAnswerToChat = (answer) => {
      setChatHistory([...chatHistory, { type: 'user', text: answer }]);
    };

    const addQuestionToChat = (question) => {
      setChatHistory([...chatHistory, { type: 'question', text: question }]);
    };

    const updateUserData = (data) => {
      setUserData({ ...userData, ...data });
    };

    const sendUserDataToServer = () => {
      fetch('http://localhost:8000/anlasmali_bosanma/generate_anlasmali_bosanma_dilekce/', { // URL of your local Python server
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
      })
      .then(response => response.json())
      .then(data => {
        
          displayPDF(data.pdf_url); // Function to display or download the PDF
      })
      .catch((error) => console.error('Error:', error));
    };

    const displayPDF = (pdfURL) => {
        const chatArea = document.getElementById('chatArea');
        const chatOutput = document.getElementById('chatOutput');

        // Check if the document display area already exists
        let documentDisplayArea = document.getElementById('document-display-area');
        
        if (!documentDisplayArea) {
            // Hide the chat output instead of removing it
            if (chatOutput) {
                chatOutput.style.display = 'none';
            }
            // Create a new container for displaying the document
            documentDisplayArea = document.createElement('div');
            documentDisplayArea.id = 'document-display-area';
            documentDisplayArea.style.padding = '0 20px'; // Add padding to left and right
            documentDisplayArea.style.textAlign = 'center'; // Center content
            documentDisplayArea.style.width = '100%'; // Full width
            chatArea.appendChild(documentDisplayArea);
        } else {
            // Clear existing content in the document display area
            documentDisplayArea.innerHTML = '';
        }

        // Embed the PDF
        const pdfEmbed = document.createElement('iframe');
        pdfEmbed.src = pdfURL;
        pdfEmbed.style.width = '50%'; // Make it take full width of the container
        pdfEmbed.style.height = '650px';

        const buttonContainer = document.createElement('div');
        buttonContainer.style.display = 'flex'; // Use flexbox
        buttonContainer.style.justifyContent = 'space-between'; // Space evenly between buttons
        buttonContainer.style.width = '20%'; // Adjust width as needed
        buttonContainer.style.margin = '0 auto'; // Center the container horizontally

        // Create the download link
        const downloadLink = document.createElement('a');
        downloadLink.href = pdfURL;
        downloadLink.textContent = 'İndir';
        downloadLink.download = 'Dilekce.pdf';
        downloadLink.target = '_blank';
        downloadLink.className = 'btn btn-primary';
        downloadLink.style.display = 'block';
        downloadLink.style.width = '100px';

        const nextButton = document.createElement('button');
        nextButton.textContent = 'İlerle';
        nextButton.className = 'btn btn-primary';
        nextButton.style.width = '100px';
        nextButton.onclick = () => {
            // Toggle visibility of chat area and document display area
            if (chatOutput) {
                chatOutput.style.display = 'block'; // Show chat output
            }
            documentDisplayArea.style.display = 'none'; // Hide document display area
            navigate('/anlasmali-bosanma-protokol'); // Your existing navigation logic
        };

        buttonContainer.appendChild(downloadLink);
        buttonContainer.appendChild(nextButton);

        // Append the PDF embed and download link to the document display area
        documentDisplayArea.appendChild(pdfEmbed);
        documentDisplayArea.appendChild(buttonContainer);
    };

    const handleSubmit = () => {
      addAnswerToChat(currentInput);
      let updatedData = {};
      if(step <= 7){
          switch(step) {
              case 0:
                updatedData = { userName: currentInput };
                break;
              case 1:
                updatedData = { userNationalID: currentInput };
                break;
              case 2:
                updatedData = { userAddress: currentInput };
                break;
              case 3:
                updatedData = { spouseName: currentInput };
                break;
              case 4:
                updatedData = { spouseNationalID: currentInput };
                break;
              case 5:
                updatedData = { spouseAddress: currentInput };
                break;
              case 6:
                updatedData = { marriageDate: currentInput };
                break;
              case 7:
                updatedData = { childrenCount: parseInt(currentInput, 10) };
                break;
            }
      } else if (step >= 8 && userData.childrenCount > 0) {
          const childIndex = Math.floor((step - 8) / 3);
          const childDataStep = (step - 8) % 3;
          let child = userData.childrenInformationList[childIndex] || {};

          switch(childDataStep) {
              case 0:
              child.name = currentInput;
              break;
              case 1:
              child.nationalID = currentInput;
              break;
              case 2:
              child.dogumTarihi = currentInput;
              break;
          }

          const updatedChildren = [...userData.childrenInformationList];
          updatedChildren[childIndex] = child;
          updatedData = { childrenInformationList: updatedChildren };

          if (childDataStep === 2 && childIndex + 1 < userData.childrenCount) {
              setCurrentChild(childIndex + 1);
          }
      }

      updateUserData(updatedData);
      setStep(step + 1);
      setCurrentInput('');
    };
    
    useEffect(() => {
      const questions = [
          'Adınız nedir?',
          'TC kimlik numaranız nedir?',
          'Adresiniz nedir?',
          'Eşinizin adı nedir?',
          'Eşinizin TC kimlik numarası nedir?',
          'Eşinizin adresi nedir?',
          'Evlilik tarihiniz nedir? (GG.AA.YYYY)',
          'Çocuk sayınız nedir?'
      ];

      for (let i = 0; i < userData.childrenCount; i++) {
          questions.push(`${i + 1}. çocuğunuzun adı nedir?`);
          questions.push(`${i + 1}. çocuğunuzun kimlik numarası nedir?`);
          questions.push(`${i + 1}. çocuğunuzun doğum tarihi nedir? (GG.AA.YYYY)`);
      }

      
      if (step < (dynamicTotalSteps)) {
          addQuestionToChat(questions[step]);
      } else {
          sessionStorage.setItem('userData', JSON.stringify(userData));
        
          sendUserDataToServer();
      }
      
    }, [step, userData.childrenCount]);

    const dynamicTotalSteps = 8 + (userData.childrenCount * 3);

    return (
      <div className="chat-container">
        <div className="progress-panel">
          <div className="navigation-container">
            <div className="navigation-title">Adımlar</div>
            <div className="gesture-detector" id="dilekce">Boşanma Dilekçesi</div>
            <div className="gesture-detector" id="protokol">Boşanma Protokolü</div>
          </div>
          <div className="progress-container">
              <div className="progress-text">
                  Tamamlanma Oranı: {calculateProgress()}%
              </div>
              <div className="progress-bar" style={{ width: `${calculateProgress()}%` }}></div>
          </div>
        </div>
        <div className="chat-area" id="chatArea">
          <div id="chatOutput">
            {chatHistory.map((message, index) => (
              <div key={index} className={`chat-message ${message.type}`}>
                {message.type === 'user' ? (
                  <img src='./assets/images/mv_logo.png' alt="User" className="profile-photo" />
                ) : (
                  <img src='./assets/google_logo.png' alt="System" className="system-logo" />
                )}
                {message.text}
              </div>
            ))}
          </div>
          {step < dynamicTotalSteps && (
            <div className="input-area">
              <input
                id="userInput"
                type="text"
                value={currentInput}
                onChange={handleInputChange}
              />
              <button id="submitButton" onClick={handleSubmit}>
                Gönder
              </button>
            </div>
          )}
        </div>
      </div>
    );
};

export default AnlasmaliBosanmaDilekceForm;

