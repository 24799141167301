// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, onSnapshot} from 'firebase/firestore'; // If you're using Firestore
import { getStorage } from 'firebase/storage'; // If you're using Firebase Storage

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC3XPSTN2P8YcPEGisEAFtfffmlQdHbHCQ",
    authDomain: "muvekkil-5f24a.firebaseapp.com",
    projectId: "muvekkil-5f24a",
    storageBucket: "muvekkil-5f24a.appspot.com",
    messagingSenderId: "684981400758",
    appId: "1:684981400758:web:813a56caa5b234aee72217",
    measurementId: "G-EBRH1Q7S9W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firestore (if you're using it)
const db = getFirestore(app);

// Initialize Firebase Storage (if you're using it)
const storage = getStorage(app);

// Export the necessary functionalities
export { app, auth, db, storage };
