import {React, useState, useContext} from 'react'
import {useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilPhone, cilUser } from '@coreui/icons'
import { auth, db } from '../firebaseConfig';
import { collection, doc, setDoc, updateDoc, Timestamp, arrayUnion, getDoc , addDoc} from "firebase/firestore";

const Register = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [validationPassword, setValidationPassword] = useState('');
  const { signUp } = useContext(AuthContext); // Using login from AuthContext
  const navigate = useNavigate();

  {/*const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== validationPassword) {
      alert('Girilen parolalar aynı olmalı');
      return;
    }

    try {
      await signUp(email, password);
      navigate('/dokuman-arama-page');
    } catch (error) {
      console.error("Signup failed:", error);

      // Handle Firebase errors
      if (error.code === 'auth/email-already-in-use') {
        alert('Bu email zaten kayıtlı.');
      } else {
        // General error message for other errors
        alert('An error occurred during signup.');
      }
    }
  
  };*/}

  const handleSubmit = async (e) => {
      e.preventDefault();

      // Check if the email field is empty
      if (!email.trim()) {
          alert('Lütfen bir e-posta adresi giriniz.');
          return;
      }

      // Check if the email is in a correct format using a simple regex pattern
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
          alert('Geçerli bir e-posta adresi giriniz.');
          return;
      }

      try {
          // If email is valid, save to Firestore
          await saveDemoDataToFirestore(email, name, phoneNumber);
          alert('İletişim bilgilerini aldık, en kısa zamanda sana ulaşacağız!');
      } catch (error) {
          console.error("Email retrieval failed:", error);
          alert('E-posta kaydedilirken bir hata oluştu. Lütfen tekrar deneyiniz.');
      }
  };


  const saveDemoDataToFirestore = async (email, name, phone) => {
    
    const emailsRef = collection(db, `emails/`);      
    const data = {
        email: email,
        name: name,
        phone: phone,
        createdAt: Timestamp.fromDate(new Date()),  // Store the current time as creation time
    };

    try {
        await addDoc(emailsRef, data);  // Save the search object to Firestore
    } catch (error) {
        console.error("Error saving search query to Firestore:", error);
    }
};



  return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit}>
                  <h1>Üye Ol</h1>
                  <p className="text-body-secondary">Hesabını oluştur</p>
                  <p className="text-muted">Leagle'ın yenilikçi ürünlerini ilk deneyimleyecek şanslı kullanıcılardan biri olmak için hemen bizimle iletişime geçin: <a href="mailto:info@leagleapp.com">info@leagleapp.com</a></p>
                  <p className="text-muted">Biz size ulaşalım:</p>
                  <CInputGroup className="mb-3">
                    <CInputGroupText><CIcon icon={cilUser} /></CInputGroupText>
                    <CFormInput
                      style={{fontSize:'12px'}}
                      value={name} 
                      onChange={e => setName(e.target.value)} 
                      placeholder="İsim" 
                       />
                  </CInputGroup>    
                  <CInputGroup className="mb-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput
                      style={{fontSize:'12px'}}
                      value={email} 
                      onChange={e => setEmail(e.target.value)} 
                      placeholder="Email" 
                      autoComplete="email" />
                  </CInputGroup>             
                  <CInputGroup className="mb-3">
                  <CInputGroupText><CIcon icon={cilPhone} /></CInputGroupText>
                    <CFormInput
                      style={{fontSize:'12px'}}
                      value={phoneNumber} 
                      onChange={e => setPhoneNumber(e.target.value)} 
                      placeholder="Telefon Numarası" 
                       />
                  </CInputGroup>  
                  <div className="d-grid">
                    <CButton style={{fontSize:'12px', color:'white'}} type="submit" color="success">Demo talep et</CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
  

  

  {/*return (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit}>
                  <h1>Üye Ol</h1>
                  <p className="text-body-secondary">Hesabını oluştur</p>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput
                      type="email" 
                      value={email} 
                      onChange={e => setEmail(e.target.value)} 
                      placeholder="Email" 
                      autoComplete="email" />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      value={password} 
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                      placeholder="Parola"
                      autoComplete="current-password"
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      value={validationPassword} 
                      onChange={e => setValidationPassword(e.target.value)}
                      type="password"
                      placeholder="Parolayı tekrarlayın"
                      autoComplete="current-password"
                    />
                  </CInputGroup>
                  <div className="d-grid">
                    <CButton type="submit" color="success">Üye Ol</CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )*/}
}

export default Register
