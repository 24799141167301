import {
    CButton,
    CCard,
    CCardBody, CCardHeader,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormLabel,
    CRow,
    CWidgetStatsE
} from "@coreui/react";
import {CChartBar} from "@coreui/react-chartjs";
import React, {useEffect, useState} from "react";
import {auth, db} from "../firebaseConfig";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import default_profile_image from '../assets/images/default_profile_image.png';


const ProfileView = () =>{
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [profileData, setProfileData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        age: '',
        occupation: '',
        organization: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                setUser(currentUser);
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                if (userDoc.exists()) {
                    setProfileData(userDoc.data());
                }
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (user) {
            try {
                await updateDoc(doc(db, 'users', user.uid), {
                    name: profileData.name,
                    email: profileData.email,
                    phoneNumber: profileData.phoneNumber,
                    age: profileData.age,
                    occupation: profileData.occupation,
                });
                alert('Profiliniz başarıyla güncellendi!');
            } catch (error) {
                console.error('Error updating profile:', error);
                alert('Profili güncellerken bir hata oluştu!');
            }
        }
    };

    if (loading) {
        return <div></div>;
    }

    return(

      <CContainer className="profile" style={{ maxWidth: '600px', margin: 'auto', textAlign: 'center', padding: '20px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
      <CCard>
        <CCardHeader style={{ color: '#555' }}>Profil</CCardHeader>
        <CCardBody>
          <div style={{ marginBottom: '20px' }}>
            <img src={user.photoURL || default_profile_image} alt="Profile" style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' }} />
          </div>
          <CForm onSubmit={handleSubmit}>
            <CRow className="mb-3">

              <CCol md="12">
                <p style={{fontSize:'16px'}}>{profileData.name}</p>
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol md="4" className="text-right">
                <CFormLabel htmlFor="email">Email:</CFormLabel>
              </CCol>
              <CCol md="8">
                <CFormInput type="text" id="email" name="email" value={profileData.email} onChange={handleChange} readOnly />
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol md="4" className="text-right">
                <CFormLabel htmlFor="organization">Organizasyon:</CFormLabel>
              </CCol>
              <CCol md="8">
                <CFormInput type="text" id="organization" name="organization" value={profileData.organization} readOnly />
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol md="4" className="text-right">
                <CFormLabel htmlFor="occupation">Rol:</CFormLabel>
              </CCol>
              <CCol md="8">
                <CFormInput type="text" id="occupation" name="occupation" value={profileData.occupation} onChange={handleChange} />
              </CCol>
            </CRow>
            <CRow className="mb-3">
              <CCol md="4" className="text-right">
                <CFormLabel htmlFor="phoneNumber">Telefon Numarası:</CFormLabel>
              </CCol>
              <CCol md="8">
                <CFormInput type="text" id="phoneNumber" name="phoneNumber" value={profileData.phoneNumber} onChange={handleChange} />
              </CCol>
            </CRow>
            <CButton type="submit" color="primary" style={{ marginTop: '20px' }}>Güncelle</CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CContainer>

    );
}

export default ProfileView;