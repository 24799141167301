import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/chat_form.css';
import InputAreaComponentRenderer from '../components/InputAreaComponentRenderer';


const AnlasmaliBosanmaProtokolForm = () => {

    const navigate = useNavigate();

    const anlasmaliBosanmaData = JSON.parse(sessionStorage.getItem('userData'));

    const calculateProgress = () => {
      return ((step / dynamicTotalSteps) * 100).toFixed(0);
    };

    const [step, setStep] = useState(0);
    const [userData, setUserData] = useState(anlasmaliBosanmaData)
    const [currentInput, setCurrentInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [dynamicTotalSteps, setDynamicTotalSteps] = useState(2);
    const [questions, setQuestions] = useState([])

    const handleInputChange = (event) => {
        let input = event.target.value;
        setCurrentInput(input);
    };

    const addAnswerToChat = (answer) => {
        setChatHistory([...chatHistory, { type: 'user', text: answer }]);
    };

    const addQuestionToChat = (question) => {
        setChatHistory([...chatHistory, { type: 'question', text: question }]);
    };

    const updateUserData = (key , info) => {
        setUserData(prevUserData => ({
          ...prevUserData,
          [key]: info
      }));
    };

    const updateChildrenData = (key, childIndex, childInfoKey, info) => {
        setUserData(prevUserData => {
            const updatedChildren = [...prevUserData[key]];
            const updatedChild = { ...updatedChildren[childIndex], [childInfoKey]: info };
            updatedChildren[childIndex] = updatedChild;
            return { ...prevUserData, [key]: updatedChildren };
        });
    };



    const sendUserDataToServer = () => {
        fetch('http://localhost:8000/generate_anlasmali_bosanma_protokol/', { // URL of your local Python server
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        })
        .then(response => response.json())
        .then(data => {
            displayPDF(data.pdf_url); // Function to display or download the PDF
        })
        .catch((error) => console.error('Error:', error));
    };

    const displayPDF = (pdfURL) => {
        const chatArea = document.getElementById('chatArea');
        
        // Check if the document display area already exists
        let documentDisplayArea = document.getElementById('document-display-area');
        
        if (!documentDisplayArea) {
            const chatOutput = document.getElementById('chatOutput');
            if (chatOutput) {
                chatArea.removeChild(chatOutput);
            }
            // Create a new container for displaying the document
            documentDisplayArea = document.createElement('div');
            documentDisplayArea.id = 'document-display-area';
            documentDisplayArea.style.padding = '0 20px'; // Add padding to left and right
            documentDisplayArea.style.textAlign = 'center'; // Center content
            documentDisplayArea.style.width = '100%'; // Full width
            chatArea.appendChild(documentDisplayArea);
        } else {
            // Clear existing content in the document display area
            documentDisplayArea.innerHTML = '';
        }

        // Embed the PDF
        const pdfEmbed = document.createElement('iframe');
        pdfEmbed.src = pdfURL;
        pdfEmbed.style.width = '50%'; // Make it take full width of the container
        pdfEmbed.style.height = '650px';

        const buttonContainer = document.createElement('div');
        buttonContainer.style.display = 'flex'; // Use flexbox
        buttonContainer.style.justifyContent = 'space-between'; // Space evenly between buttons
        buttonContainer.style.width = '20%'; // Adjust width as needed
        buttonContainer.style.margin = '0 auto'; // Center the container horizontally

        // Create the download link
        const downloadLink = document.createElement('a');
        downloadLink.href = pdfURL;
        downloadLink.textContent = 'İndir';
        downloadLink.download = 'Dilekce.pdf';
        downloadLink.target = '_blank';
        downloadLink.className = 'btn btn-primary';
        downloadLink.style.display = 'block';
        downloadLink.style.width = '100px';

        const nextButton = document.createElement('button');
        nextButton.textContent = 'İlerle';
        nextButton.className = 'btn btn-primary';
        nextButton.style.width = '100px';
        nextButton.onclick = () => navigate('/profile');

        buttonContainer.appendChild(downloadLink);
        buttonContainer.appendChild(nextButton);

        // Append the PDF embed and download link to the document display area
        documentDisplayArea.appendChild(pdfEmbed);
        documentDisplayArea.appendChild(buttonContainer);
    };

    const handleSubmit = (input) => {  
      addAnswerToChat(input);
      setStep(step + 1);
      setDynamicTotalSteps(questions.length);
    };

    useEffect(() => {
        
        const questions = [];
        for (let i = 0; i < userData.childrenCount; i++) {
            const childName = userData.childrenInformationList[i].name
            questions.push(`${childName}'in velayeti kimde olacak? (Anne veya Baba)`);
            questions.push(`${childName} kimde kalacak? (Anne veya Baba)`);
            questions.push(`Velayeti olmayan taraf '${childName}'i haftanın hangi günleri görebilecek?`);
        }
        if(userData.childrenCount > 0){
            questions.push('Çocuklar anne babasına giderken kimler tarafından alınabilecek?');
        }

        questions.push(`Evlilik tarihiniz ${anlasmaliBosanmaData.marriageDate}. Bu tarihte yapılan bir evliliğe göre aşağıdaki mal paylaşım rejimlerinden birini yapabilirsiniz. Hangi tür mal paylaşımı yapmak istersiniz?`);
        questions.push('Boşanma sonrası nafaka verilecek mi?');
        questions.push("Size iştirak nafakası için okul ve yaşam masraflarına asgari katılımın sağlanması adına çocuk başına 2024 yılı birinci yarısı için 2500 TL, yoksulluk nafakası ise 3000 TL öneriyoruz. Miktarı yeniden belirlemek isterseniz aşağıdaki kutucukları düzenleyebilirsiniz.");
        questions.push("Nafaka ödemesi ne sıklıkta yapılacaktır?");
        questions.push("Nafaka artışı her yılın ocak ayında TÜFE veya asgari ücret artışından artışı yüksek olan yüzdeye göre yapılmasını öneririz. Sizin için uygun artışı seçiniz.");

        setQuestions(questions);
        setDynamicTotalSteps(questions.length);

        if (questions.length > 0) {
            addQuestionToChat(questions[0]);
        }

    }, []);

    useEffect(() => {
        if (questions.length > step) {
            addQuestionToChat(questions[step]);
        } else {
            sessionStorage.setItem('userData', JSON.stringify(userData));
            sendUserDataToServer();
            //navigate('/profile');
        }
    }, [step]);

    return (
        <div className="chat-container">
          <div className="progress-panel">
            <div className="navigation-container">
            <div className="navigation-title">Adımlar</div>
            <div className="gesture-detector" id="dilekce">Boşanma Dilekçesi</div>
            <div className="gesture-detector" id="protokol">Boşanma Protokolü</div>
            </div>
            <div className="progress-container">
                <div className="progress-text">
                    Tamamlanma Oranı: {calculateProgress()}%
                </div>
                <div className="progress-bar" style={{ width: `${calculateProgress()}%` }}></div>
            </div>
          </div>
          <div className="chat-area" id="chatArea">
            <div id="chatOutput">
              {chatHistory.map((message, index) => (
                <div key={index} className={`chat-message ${message.type}`}>
                  {message.type === 'user' ? (
                    <img src='./assets/images/mv_logo.png' alt="User" className="profile-photo" />
                  ) : (
                    <img src='./assets/google_logo.png' alt="System" className="system-logo" />
                  )}
                  {message.text}
                </div>
              ))}
            </div>
            {step < dynamicTotalSteps && (
              <div className="input-area">
                {InputAreaComponentRenderer(step, anlasmaliBosanmaData, handleSubmit, updateUserData, updateChildrenData)}
              </div>
            )}
          </div>
        </div>
      );

};

export default AnlasmaliBosanmaProtokolForm;