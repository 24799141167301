import React, {useState, useEffect, useCallback, useRef, useContext} from 'react';
import {CInputGroup, CFormInput, CContainer, CRow, CCol, CButton, CSpinner} from '@coreui/react';
import axios from 'axios';
import VerticalNavbar from '../components/VerticalNavbar';
import {auth, db} from '../firebaseConfig';
import {collection, doc, setDoc, updateDoc, Timestamp, arrayUnion, getDoc} from "firebase/firestore";
import {marked} from 'marked';
import {parseIctihatBody} from '../utils/documentSearchUtils';
import CIcon from '@coreui/icons-react'
import {cibCircle} from '@coreui/icons'
import {AuthContext} from '../contexts/AuthContext';
import config from "../config";

const ImprovedSearchView = () => {
    const {user} = useContext(AuthContext);
    const [query, setQuery] = useState('');
    const [firstQuery, setFirstQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [currentThreadId, setCurrentThreadId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showHeader, setShowHeader] = useState(true);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [marginTop, setMarginTop] = useState('50vh');
    const [referenceDocuments, setReferenceDocuments] = useState([]);


    const addReferenceDocuments = (newDocuments) => {
        if (newDocuments.length > 0) {
            setReferenceDocuments(prevDocuments => [...prevDocuments, ...newDocuments]);
        }
    };

    const handleImprovedSearch = useCallback(async (query) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${config.apiEndpoint}/chat/claude_assistant/`, {
                prompt: query, isNewThread: firstQuery === '', threadId: currentThreadId,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
          
            await setCurrentThreadId(response.data.thread_id);
            const newResponse = {speaker: 'ai', message: response.data.response};
            addReferenceDocuments(response.data.reference_documents);
            await saveConversationToFirestore(query, newResponse, response.data.reference_documents, response.data.thread_id);
            setSearchResults(prevResults => [...prevResults, newResponse]);
        } catch (error) {
            console.error('Error during API request:', error);
            setError('Failed to fetch search results');
        } finally {
            setIsLoading(false);
        }
    }, [firstQuery, currentThreadId]);

    const handleDocumentClick = useCallback((document) => {
        setSelectedDocument(document);
        setShowDocumentModal(true);
    }, []);

    const onSearchClick = useCallback((query) => {
        if (!firstQuery) {
            setFirstQuery(query);
            setShowHeader(false);
        } else {
            setSearchResults(prevResults => [...prevResults, {speaker: 'User', message: query}]);
        }
        handleImprovedSearch(query);
    }, [firstQuery, handleImprovedSearch]);

    const handleClick = useCallback((question) => {
        setQuery(question);
        onSearchClick(question);
    }, [onSearchClick]);


    useEffect(() => {
        const updateMargin = () => {
            if (!firstQuery) {
                setMarginTop(`${window.innerHeight / 2 - 100}px`);
            } else {
                setMarginTop('auto');
            }
        };
        updateMargin();
        window.addEventListener('resize', updateMargin);
        return () => window.removeEventListener('resize', updateMargin);
    }, [firstQuery]);


    const saveConversationToFirestore = async (query, newResponse, referenceDocuments, thread_id) => {
        const user = auth.currentUser;
        if (!user) {
            console.error("No authenticated user found.");
            return;
        }

        const userId = user.uid;
        const userThreadsRef = collection(db, `users/${userId}/threads`);
        const threadRef = doc(userThreadsRef, thread_id);
        const docSnap = await getDoc(threadRef);

        if (docSnap.exists()) {
            try {
                await updateDoc(threadRef, {
                    responses: arrayUnion({speaker: 'User', message: query}, newResponse)
                });
            } catch (error) {
                console.error("Error updating conversation in Firestore:", error);
            }
        } else {
            const thread = {
                query,
                responses: [{speaker: 'User', message: query}, newResponse],
                createdAt: Timestamp.fromDate(new Date()),
                referenceDocuments: referenceDocuments
            };
            try {
                await setDoc(threadRef, thread);
            } catch (error) {
                console.error("Error creating new conversation thread with currentThreadId in Firestore:", error);
            }
        }
    };

    const fetchPreviousThreadFromFirestore = async (thread_id) => {
        const user = auth.currentUser;
        if (!user) {
            console.error("No authenticated user found.");
            return;
        }

        const userId = user.uid;
        const userThreadsRef = collection(db, `users/${userId}/threads`);
        const threadRef = doc(userThreadsRef, thread_id);
        const docSnap = await getDoc(threadRef);

        if (docSnap.exists()) {
            setCurrentThreadId(thread_id);
            const threadData = docSnap.data();
            const messages = threadData.responses.map(response => ({
                speaker: response.speaker, message: response.message
            }));

            setFirstQuery(threadData.query);
            setSearchResults(messages);
            setReferenceDocuments(threadData.referenceDocuments);
        } else {
            console.error("No thread found with the provided ID.");
        }
    };

    const ReferenceDocuments = ({firstQuery, referenceDocuments}) => {
        if (!referenceDocuments || referenceDocuments.length === 0) {
            return (<div className="reference-documents-container">
                    {firstQuery && <h2 style={{fontSize: '30px'}}>{firstQuery}</h2>}
                    <div style={{width: '100%', height: '1px', backgroundColor: '#ccc', marginTop: '20px'}}></div>
                </div>);
        }
        return (<div className="reference-documents-container">
                {firstQuery && <h2 style={{fontSize: '30px'}}>{firstQuery}</h2>}
                <h1>Kaynaklar</h1>
                {referenceDocuments.map((doc, index) => (<div key={doc.id || index} className="reference-document-box"
                                                              onClick={() => handleDocumentClick(doc)}>
                        {doc.esas_no}
                    </div>))}
                <div style={{width: '100%', height: '1px', backgroundColor: '#ccc', marginTop: '20px'}}></div>
            </div>);
    };

    const DocumentModal = () => {
        if (!showDocumentModal || !selectedDocument) return null;

        return (<div className="modal-container">
                <div className="modal-overlay" onClick={() => setShowDocumentModal(false)}></div>
                <div className="modal-content">
                    <button className="close-button" onClick={() => setShowDocumentModal(false)}>×</button>
                    <p>{parseIctihatBody(selectedDocument.ictihat_body)}</p>
                </div>
            </div>);
    };

    const getFirstName = (fullName) => {
        return fullName.split(' ')[0];
    };

    const SearchResults = ({searchResults, error}) => {
        if (error) {
            return <div>Error: {error}</div>;
        }

        if (searchResults.length === 0) {
            return null;
        }

        let nameUser = (user.displayName || user.email || 'Kullanıcı');
        nameUser = getFirstName(nameUser);
        return (<div>
                {searchResults.map((chat, index) => (<div key={index}>
                        <strong>{(chat.speaker == 'ai') ? 'Leagle AI' : nameUser}:</strong>
                        <div dangerouslySetInnerHTML={{__html: marked(chat.message)}}/>
                    </div>))}
            </div>);
    };

    const SearchAndSuggest = ({query, setQuery, onSearchClick, firstQuery}) => {
        const questions = ["Fikri mülkiyet ihlallerine dair güncel kararlar neler?", "Anlaşmalı boşanma için dava dilekçesi taslağı", "Tüketici hakları ihlali ile cezalandırılan kurum kararları", "Leagle nasıl fark yaratıyor?"];
        const inputRef = useRef(null);
        const [localQuery, setLocalQuery] = useState("");

        const handleChange = (e) => {
            setLocalQuery(e.target.value);
        };

        const handleLocalSearchClick = () => {
            onSearchClick(localQuery);
            setLocalQuery('');
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                handleLocalSearchClick();
            }
        };

        return (<div style={{width: '100%'}}>
                {!firstQuery && <h1 style={{textAlign: 'center'}}>Bilginin başladığı yer.</h1>}
                <CInputGroup>
                    <CFormInput
                        type="text"
                        ref={inputRef}
                        value={localQuery}
                        onChange={handleChange}
                        placeholder={firstQuery ? "Takip soruları sorun..." : "Dilediğini sor..."}
                        aria-label="Search input"
                        className="improvedsearchsearchInput"
                        onKeyDown={handleKeyDown}
                    />
                    <CButton onClick={handleLocalSearchClick} disabled={isLoading} color="primary">{isLoading ?
                        <CIcon icon={cibCircle}/> : <a>Gönder</a>}</CButton>
                </CInputGroup>
                {!firstQuery && (<div style={{
                        marginTop: "20px",
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}>
                        {questions.slice(0, 4).map((question, index) => (
                            <div key={index} style={{width: '48%', marginBottom: '10px'}}>
                                <CButton color="secondary" onClick={() => handleClick(question)} style={{
                                    width: '100%', whiteSpace: 'normal', lineHeight: '1.5', fontSize: '15px'
                                }}>
                                    {question}
                                </CButton>
                            </div>))}
                    </div>)}
                    {!firstQuery && <p style={{textAlign: 'center'}}>Leagle can make mistakes.</p>}
                </div>);


    };


    return (<CContainer className='full-height'>
            <CRow className='full-height'>
                <CCol>
                    <VerticalNavbar fetchPreviousThread={fetchPreviousThreadFromFirestore}/>
                </CCol>
                <CCol xs={10} style={{
                    display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', paddingInline: '2%'
                }}>
                    <CContainer className="reference-documents-container">
                        <ReferenceDocuments
                            firstQuery={firstQuery}
                            referenceDocuments={referenceDocuments}
                            handleDocumentClick={(doc) => showDocumentModal(doc)}
                        />
                    </CContainer>
                    <CContainer>
                        <SearchResults searchResults={searchResults} error={error}/>
                        {isLoading ? <CContainer style={{
                            justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom: '2vh'
                        }}><CSpinner></CSpinner></CContainer> : null}
                    </CContainer>
                    <div style={{
                        marginTop: marginTop, width: '100%', transition: 'margin-top 0.5s ease-out'
                    }}>
                        <SearchAndSuggest
                            query={query}
                            setQuery={setQuery}
                            onSearchClick={onSearchClick}
                            firstQuery={firstQuery}
                        />
                    </div>
                </CCol>

                {showDocumentModal && <DocumentModal/>}

            </CRow>

        </CContainer>);
};

export default ImprovedSearchView;
