import React, { useState } from 'react';
import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import ProfileNavbar from './ProfileNavbar';
import ProfileView from './ProfileView';
import FavoritesView from './FavoritesView';
import StatisticsView from './StatisticsView';

const Profile = () => {
  const [activeTab, setActiveTab] = useState('profile');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
      <CContainer className='full-height'>
        <CRow className='full-height'>
          <CCol xs={2}>
            <ProfileNavbar onTabChange={handleTabChange} />
          </CCol>
          <CCol xs={10} style={{display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto', paddingInline: '2%'}}>
            <CContainer className="reference-documents-container">
              {activeTab === 'profile' && <ProfileView />}
              {activeTab === 'favorites' && <FavoritesView />}
              {activeTab === 'statistics' && <StatisticsView />}
            </CContainer>
          </CCol>
        </CRow>
      </CContainer>
  );
};

export default Profile;
