import React from 'react';

const InputAreaComponentRenderer = (step, anlasmaliBosanmaData, handleSubmit, updateUserData, updateChildrenData) => {

    // Step 0, 3, 6.. (çocuk sayısına göre) --> Evet - Hayır
    // Step 1, 4, 7.. (çocuk sayısına göre) --> Evet - Hayır
    // Step 2, 5, 8.. (çocuk sayısına göre) --> Pazartesi - Salı - Çarşamba - Perşembe - Cuma - Cumartesi - Pazar - Gönder
    // En az 1 çocuk olduğu durumdan devam ediyorum:
    // Çocuk varsa: step 3 --> Sadece anne ve baba - Anne, baba ve onların gönderdiği kişiler
    // Step 4 (Mal ayrılığı) --> Edinilmiş mallara katılma rejimi - Mal ayrılığı rejimi - Paylaşmalı mal ayrılığı rejimi
    // Step 5 (NafakaVerilecekMi) --> Evet - Hayır
    // NafakaVerilecekMi Evet ise,
        // Step 6 (NafakaMiktari) --> 2 ayrı text input : 
            // İştirak Nafakası: {INPUT FIELD}
            // Yoksulluk Nafakası: {INPUT FIELD}
        // Step 7 (NafakaOdemeSikligi) --> Her ay - İki haftada bir - 3 ayda bir - yılda bir
        // Step 8 (NafakaArtisPlani) --> TÜFE ve asgari ücret artışının yüksek olanı (Önerilen) - {Yıllık sabit yüzde: {INPUT FIELD} - Gönder}

    let daysInput = '';
    let nafakaMiktari = {};

    const saveDay = (day) => {
        daysInput = daysInput + ',' + day;
    }
    
    const clearDaysInput = () => {
        daysInput = '';
    }

    const saveNafakaAmount = () => {
        nafakaMiktari['istirakNafakasi'] = document.getElementById('istirakNafakasi').value;
        nafakaMiktari['yoksullukNafakasi'] = document.getElementById('yoksullukNafakasi').value;
        return JSON.stringify(nafakaMiktari); // Convert object to a string
    }    

    const saveNafakaArtisYuzdesi = () => {
        return (document.getElementById('nafakaArtisYuzdesi'));
    }

    const renderInputAreaComponents = () => {

        // Çocuk varsa
        if(anlasmaliBosanmaData.childrenCount > 0){
            if(step < (3 * anlasmaliBosanmaData.childrenCount)){
                let childIndex = Math.floor(step / 3);
                switch(step % 3){
                    
                    case 0:
                        return(
                            <>
                                <button id="submitButton" onClick={() => { handleSubmit('Anne'); updateChildrenData('childrenInformationList', childIndex, 'velayet', 'Anne'); }}>Anne</button>
                                <button id="submitButton" onClick={() => { handleSubmit('Baba'); updateChildrenData('childrenInformationList', childIndex, 'velayet', 'Baba'); }}>Baba</button>
                            </>
                        )
                    case 1:
                        return(
                            <>
                                <button id="submitButton" onClick={() => { handleSubmit('Anne'); updateChildrenData('childrenInformationList', childIndex, 'kimdeKalacak', 'Anne'); }}>Anne</button>
                                <button id="submitButton" onClick={() => { handleSubmit('Baba'); updateChildrenData('childrenInformationList', childIndex, 'kimdeKalacak', 'Baba'); }}>Baba</button>
                            </>
                        )
                    case 2:
                        return(
                            <>
                                <button id="submitButton" onClick={() => saveDay('Pazartesi')}>Pazartesi</button>
                                <button id="submitButton" onClick={() => saveDay('Salı')}>Salı</button>
                                <button id="submitButton" onClick={() => saveDay('Çarşamba')}>Çarşamba</button>
                                <button id="submitButton" onClick={() => saveDay('Perşembe')}>Perşembe</button>
                                <button id="submitButton" onClick={() => saveDay('Cuma')}>Cuma</button>
                                <button id="submitButton" onClick={() => saveDay('Cumartesi')}>Cumartesi</button>
                                <button id="submitButton" onClick={() => saveDay('Pazar')}>Pazar</button>
                                <button id="submitButton" onClick={() => { handleSubmit(daysInput); updateChildrenData('childrenInformationList', childIndex, 'gorusmeGunleri', daysInput); clearDaysInput() }}>Gönder</button>
                            </>
                        )
                }
            }
    
            if(step == 3 *anlasmaliBosanmaData.childrenCount){
                return(
                    <>
                        <button id="submitButton" onClick={() => { handleSubmit('Sadece anne ve baba'); updateUserData('cocuklariKimlerAlabilir', 'Sadece anne ve baba'); }}>Sadece anne ve baba</button>
                        <button id="submitButton" onClick={() => { handleSubmit('Anne, baba veya onların gönderdiği kişiler'); updateUserData('cocuklariKimlerAlabilir', 'Sadece anne ve baba'); }}>Anne, baba veya onların gönderdiği kişiler</button>
                    </>
                )
            }

            let questionOffset = 3 * anlasmaliBosanmaData.childrenCount + 1

            if(step - questionOffset == 0)
                return(
                    <>
                        <button id="submitButton" onClick={() => {handleSubmit('Edinilmiş mallara katılma rejmi'); updateUserData('malRejimiTuru', 'Edinilmiş mallara katılma rejmi');}}>Edinilmiş mallara katılma rejmi</button>
                        <button id="submitButton" onClick={() => {handleSubmit('Mal ayrılığı rejmi'); updateUserData('malRejimiTuru', 'Mal ayrılığı rejmi');}}>Mal ayrılığı rejmi</button>
                        <button id="submitButton" onClick={() => {handleSubmit('Paylaşmalı mal ayrılığı rejmi'); updateUserData('malRejimiTuru', 'Paylaşmalı mal ayrılığı rejmi');}}>Paylaşmalı mal ayrılığı rejmi</button>
                    </>
                )
            else if(step - questionOffset == 1)
                return(
                    <>
                        <button id="submitButton" onClick={() => {handleSubmit('Evet'); updateUserData('nafakaVerilecekMi', 'Evet');}}>Evet</button>
                        <button id="submitButton" onClick={() => {handleSubmit('Hayır'); updateUserData('nafakaVerilecekMi', 'Hayır');}}>Hayır</button>
                    </>
                )
            else
                switch(step - questionOffset){
                    case 2:
                        return (
                            <>
                                <div>İştirak Nafakası (TL): <input type="number" id="istirakNafakasi" /></div>
                                <div>Yoksulluk Nafakası (TL): <input type="number" id="yoksullukNafakasi" /></div>
                                <button id="submitButton" onClick={() => { handleSubmit(saveNafakaAmount()); updateUserData('nafakaMiktari', saveNafakaAmount()); }}>Gönder</button>
                            </>
                        );   
                    case 3:
                        return (
                            <>
                                <button id="submitButton" onClick={() => { handleSubmit('Her ay'); updateUserData('nafakaOdemeSikligi', 'Her ay'); }}>Her ay</button>
                                <button id="submitButton" onClick={() => {handleSubmit('İki haftada bir'); updateUserData('nafakaOdemeSikligi', 'Her ay'); }}>İki haftada bir</button>
                                <button id="submitButton" onClick={() => {handleSubmit('3 ayda bir'); updateUserData('nafakaOdemeSikligi', 'Her ay'); }}>3 ayda bir</button>
                                <button id="submitButton" onClick={() => {handleSubmit('Yılda bir'); updateUserData('nafakaOdemeSikligi', 'Her ay'); }}>Yılda bir</button>
                            </>
                        );
                    case 4:
                        return (
                            <>
                                <button onClick={() => {handleSubmit('TÜFE ve asgari ücret artışının yüksek olanı (Önerilen)'); updateUserData('nafakaArtisPlani', 'TÜFE ve asgari ücret artışının yüksek olanı (Önerilen)'); }}>TÜFE ve asgari ücret artışının yüksek olanı (Önerilen)</button>
                                <div>Yıllık sabit yüzde: <input type="number" id="nafakaArtisYuzdesi" /></div>
                                <button id="submitButton" onClick={() => {handleSubmit(saveNafakaArtisYuzdesi()); updateUserData('nafakaArtisPlani', saveNafakaArtisYuzdesi())}}>Gönder</button>
                            </>
                        );
                }
        }
    };

    return renderInputAreaComponents();
};

export default InputAreaComponentRenderer;

