import React from 'react';
import { CContainer, CButton } from '@coreui/react';
import { HukukDairesiSelector, CezaDairesiSelector, DateFilter } from '../components/Filtering';

const IctihatFilterPanel = ({
                                startDateDay, setStartDateDay,
                                startDateMonth, setStartDateMonth,
                                startDateYear, setStartDateYear,
                                endDateDay, setEndDateDay,
                                endDateMonth, setEndDateMonth,
                                endDateYear, setEndDateYear,
                                selectedHukukDairesi, setSelectedHukukDairesi,
                                selectedCezaDairesi, setSelectedCezaDairesi,
                                filterResults,sortByDate, sortByRelevancy
                            }) => {
    return (
        <>
            <CContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <CButton style={{ margin: '10px' }} color="primary" onClick={sortByDate}>Tarihe Göre Sırala</CButton>
                <CButton style={{ margin: '10px' }} color="primary" onClick={sortByRelevancy}>İlgiye Göre Sırala</CButton>
            </CContainer>
            <DateFilter
                startDateDay={startDateDay}
                setStartDateDay={setStartDateDay}
                startDateMonth={startDateMonth}
                setStartDateMonth={setStartDateMonth}
                startDateYear={startDateYear}
                setStartDateYear={setStartDateYear}
                endDateDay={endDateDay}
                setEndDateDay={setEndDateDay}
                endDateMonth={endDateMonth}
                setEndDateMonth={setEndDateMonth}
                endDateYear={endDateYear}
                setEndDateYear={setEndDateYear}
            />
            <CContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <HukukDairesiSelector
                    selectedDairesi={selectedHukukDairesi}
                    setSelectedDairesi={setSelectedHukukDairesi}
                />
                <CezaDairesiSelector
                    selectedDairesi={selectedCezaDairesi}
                    setSelectedDairesi={setSelectedCezaDairesi}
                />
            </CContainer>
            <CContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <CButton style={{ margin: '10px' }} color="primary" onClick={filterResults}>Filtrele</CButton>
            </CContainer>
        </>
    );
};

export default IctihatFilterPanel;
