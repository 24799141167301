import React, { createContext, useState, useEffect } from 'react';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail 
} from 'firebase/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(getAuth(), email, password);
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(getAuth(), email, password);
  };

  const logout = () => {
    return firebaseSignOut(getAuth()).then(() => setUser(null));
  };

  // Adding resetPassword method
  const resetPassword = (email) => {
    return sendPasswordResetEmail(getAuth(), email);
  };

  return (
    <AuthContext.Provider value={{ user, signUp, login, logout, resetPassword }}>
      {children}
    </AuthContext.Provider>
  );
};
